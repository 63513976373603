import React from "react"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import mask1 from "../images/mask-1.png"
import mask2 from "../images/mask-2.png"
import mask3 from "../images/mask-3.png"
import mask4 from "../images/mask-4.png"
import mask5 from "../images/mask-5.png"

const IndexPage = () => (
  <Layout>
    <Container>
      <H1>TRD Group</H1>
      <P>Medical supply procurement</P>
      <P>
        For more information call:{" "}
        <TelephoneLink href="tel:917-658-5666">(917) 658–5666</TelephoneLink>
      </P>
      <ImageContainer>
        <UL>
          <li>
            {" "}
            <Image src={mask1} />
          </li>
          <li>
            <Image src={mask2} />
          </li>
          <li>
            <Image src={mask3} />
          </li>
          <li>
            <Image src={mask4} />
          </li>
          <li>
            <Image src={mask5} />
          </li>
        </UL>
      </ImageContainer>
    </Container>
  </Layout>
)

export default IndexPage

const Container = styled.div`
  text-align: center;
  margin: 150px auto;
  max-width: 1000px;
  padding: 0 50px;
`

const H1 = styled.h1`
  font-size: 70px;
  font-family: "Helvetica";
  font-weight: 900;
  color: #000;
  @media only screen and (min-width: 600px) {
    font-size: 100px;
  }
`
const P = styled.p`
  font-size: 20px;
  font-family: "Helvetica";
  font-weight: 400;
  margin: 20px 0;
  color: #000;
  line-height: 1.4;

  @media only screen and (min-width: 600px) {
    font-size: 40px;
  }
`
const TelephoneLink = styled.a`
  text-decoration: none;
  color: #000;
`
const ImageContainer = styled.div`
  margin-top: 100px auto auto;
  max-width: 900px;
  display: flex;
`
const Image = styled.img`
  max-height: 100%;
  max-width: 130px;
  object-fit: cover;
  vertical-align: bottom;
  width: 100%;

  @media only screen and (min-width: 600px) {
    max-width: 250px;
  }
`
const UL = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`
